import React from 'react';

import { useLocation } from 'react-router-dom';

const Confirmation = () => {
  const location = useLocation();
  const { name } = location.state || {};

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: '20px',
      }}
    >
      <h1 style={{ fontSize: '30px', color: 'white', textAlign: 'center' }}>
        Dine tips er sendt inn {name}!
      </h1>
      <p style={{ color: 'white', textAlign: 'center' }}>
        Måtte den beste tipperen vinne 🏆
        <br></br>
        <br></br>Lykke til!
      </p>
    </div>
  );
};

export default Confirmation;
