import React, { useContext, useEffect, useState } from 'react';

import { collection, getDocs } from 'firebase/firestore';

import { AppContext } from '../context/AppContext';
import { db } from '../firebase';
import { allMatches, groups } from '../matches';
import './Overview.css';
import './Loading.css';

const Overview = () => {
  const { tips, setTips } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [teamsOut, setTeamsOut] = useState([]);

  const [correctResults, setCorrectResults] = useState(null);

  // Utregningslogikk for poeng
  const calculatePoints = (prediction, correctResults) => {
    let points = 0;

    // Calculate points for group stage matches
    for (const [matchName, result] of Object.entries(prediction.groupStage)) {
      const correctMatch = correctResults.matches.find(
        (m) => m.name === matchName,
      );
      if (correctMatch && correctMatch.result === result) {
        points += 0.5;
      }
    }

    // Calculate points for round of 16 teams
    points +=
      Object.keys(prediction.roundOf16).filter((team) =>
        correctResults.correctRoundOf16Teams.includes(team),
      ).length * 1;

    // Calculate points for quarter final teams
    points +=
      Object.keys(prediction.quarterFinals).filter((team) =>
        correctResults.correctQuarterFinalTeams.includes(team),
      ).length * 1;

    // Calculate points for semi final teams
    points +=
      Object.keys(prediction.semiFinals).filter((team) =>
        correctResults.correctSemiFinalTeams.includes(team),
      ).length * 1;

    // Calculate points for final teams
    points +=
      Object.keys(prediction.finals).filter((team) =>
        correctResults.correctFinalTeams.includes(team),
      ).length * 2;

    // Calculate points for the winner
    if (prediction.winner === correctResults.correctWinner) {
      points += 3;
    }

    return points;
  };

  const getLastPlaceTips = (tips) => {
    if (tips.length === 0) return [];
    const minPoints = Math.min(...tips.map((tip) => tip.points));
    return tips.filter((tip) => tip.points === minPoints);
  };

  useEffect(() => {
    const fetchTipsAndResults = async () => {
      setLoading(true);

      // Fetch user tips
      const tipsSnapshot = await getDocs(collection(db, 'predictions'));
      const tipsData = [];
      tipsSnapshot.forEach((doc) => {
        tipsData.push({ ...doc.data(), id: doc.id });
      });

      // Fetch correct results
      const resultsSnapshot = await getDocs(collection(db, 'correctResults'));
      const resultsData = resultsSnapshot.docs.map((doc) => doc.data());
      if (resultsData.length > 0) {
        setCorrectResults(resultsData[0]);
        setTeamsOut(resultsData[0].teamsOut || []);
      }

      // Wait for correctResults to be set
      if (resultsData.length > 0) {
        const correctResults = resultsData[0];
        // Calculate and set points for each tip
        tipsData.forEach((tip) => {
          tip.points = calculatePoints(tip, correctResults);
        });

        // Sort tips by calculated points in descending order
        tipsData.sort((a, b) => {
          if (b.points === a.points) {
            return a.name.localeCompare(b.name);
          }
          return b.points - a.points;
        });

        setTips(tipsData);
      }
      setLoading(false);
    };
    fetchTipsAndResults();
  }, [setTips]);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long' };
    return new Date(dateString).toLocaleDateString('no-NO', options);
  };

  const getMatchResult = (matchId) => {
    if (correctResults && correctResults.matches) {
      const match = correctResults.matches.find((m) => m.name === matchId);
      return match ? match.result : '-';
    }
    return '-';
  };

  const isCorrect = (predicted, actual) => {
    if (actual === '-') return '';
    return predicted === actual ? 'correct-result' : 'incorrect-result';
  };

  const sortedMatches = [...allMatches].sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );

  const getClassNameForTeam = (team, correctTeams, requiredLength) => {
    if (teamsOut.includes(team)) return 'eliminated-team';
    if (!correctTeams) return '';
    if (correctTeams.includes(team)) return 'correct-result';
    if (correctTeams.length >= requiredLength) return 'incorrect-result';
    return '';
  };

  const getClassNameForWinner = (predictedWinner, actualWinner) => {
    if (teamsOut.includes(predictedWinner)) return 'eliminated-team';
    if (!actualWinner || actualWinner === '') return '';
    return predictedWinner === actualWinner
      ? 'correct-result'
      : 'incorrect-result';
  };

  const calculateStagePoints = (tip, correctTeams) => {
    if (!correctTeams) return { points: 0, correctTeams: [] };
    const correctTeamsInStage = Object.keys(tip).filter((team) =>
      correctTeams.includes(team),
    );
    return {
      points: correctTeamsInStage.length,
      correctTeams: correctTeamsInStage,
    };
  };

  const calculateGroupStagePoints = (tip) => {
    return (
      sortedMatches.filter((match) => {
        const matchId = match.name;
        const predictedResult = tip.groupStage[matchId];
        const actualResult = getMatchResult(matchId);
        return predictedResult && predictedResult === actualResult;
      }).length * 0.5
    );
  };

  const today = new Date().toISOString().split('T')[0];
  const todaysMatch = allMatches.find((match) => match.date === today);

  const getPredictionsForMatch = (matchId) => {
    const predictions = { H: [], U: [], B: [] };
    tips.forEach((tip) => {
      const result = tip.groupStage[matchId];
      if (result) {
        predictions[result].push(tip.name);
      }
    });
    return predictions;
  };

  const getGroupForTeam = (team) => {
    const group = groups.find((group) => group.teams.includes(team));
    return group ? `Gruppe ${group.name}` : '';
  };
  const lastPlaceTips = getLastPlaceTips(tips);

  const getRank = (index, tips) => {
    if (index === 0) return 1;
    if (tips[index].points === tips[index - 1].points) {
      return getRank(index - 1, tips);
    }
    return index + 1;
  };

  const getWinnerPredictions = (tips) => {
    const winnerPredictions = {};

    tips.forEach((tip) => {
      const winner = tip.winner;
      if (!winnerPredictions[winner]) {
        winnerPredictions[winner] = [];
      }
      winnerPredictions[winner].push(tip.name);
    });

    const allUsers = tips.map((tip) => tip.name);
    const result = { all: [], partial: {} };

    for (const [winner, users] of Object.entries(winnerPredictions)) {
      if (users.length === allUsers.length) {
        result.all.push(winner);
      } else {
        result.partial[winner] = users;
      }
    }

    return result;
  };

  const renderWinnerTable = (predictions) => {
    return (
      <div className="stage-section">
        <h2>Vinner (3p)</h2>
        <table className="prediction-table">
          <tbody>
            {predictions.all.map((team, index) => (
              <tr
                key={index}
                style={{ backgroundColor: 'rgba(75, 192, 192, 0.2)' }}
                className={teamsOut.includes(team) ? 'eliminated-team' : ''}
              >
                <td>{team}</td>
                <td className="user-names">Alle</td>
              </tr>
            ))}
            {Object.entries(predictions.partial).map(([team, users], index) => (
              <tr
                key={index}
                className={teamsOut.includes(team) ? 'eliminated-team' : ''}
              >
                <td>{team}</td>
                <td className="user-names">{users.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const isFirstPlace = (index, tips) => {
    return index === 0 || (index > 0 && tips[index].points === tips[0].points);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-balls">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <div className="overview-container">
      <h1 style={{ color: 'white' }}>EM-tipping 2024</h1>
      <div className="accordion">
        {tips.map((tip, index) => {
          const rank = getRank(index, tips);
          const groupStagePoints = calculateGroupStagePoints(tip);
          const {
            points: roundOf16Points,
            correctTeams: correctRoundOf16Teams,
          } = calculateStagePoints(
            tip.roundOf16,
            correctResults?.correctRoundOf16Teams,
          );
          const {
            points: quarterFinalPoints,
            correctTeams: correctQuarterFinalTeams,
          } = calculateStagePoints(
            tip.quarterFinals,
            correctResults?.correctQuarterFinalTeams,
          );
          const {
            points: semiFinalPoints,
            correctTeams: correctSemiFinalTeams,
          } = calculateStagePoints(
            tip.semiFinals,
            correctResults?.correctSemiFinalTeams,
          );
          const { points: finalPoints, correctTeams: correctFinalTeams } =
            calculateStagePoints(tip.finals, correctResults?.correctFinalTeams);
          const winnerPoints =
            tip.winner === correctResults?.correctWinner ? 3 : 0;

          return (
            <div className="accordion-item" key={tip.id}>
              <input type="checkbox" id={`accordion-item-${index}`} />
              <label htmlFor={`accordion-item-${index}`}>
                <div>
                  {' '}
                  {`${rank}. ${tip.name}`} {isFirstPlace(index, tips) && ' 🏆'}
                  {lastPlaceTips.includes(tip) && ' 💩💩💩'}
                </div>
                <div className="accordion-item-points">
                  <div> {`${tip.points}`} </div>
                  <div> &nbsp;p</div>
                </div>
              </label>
              <div className="accordion-content">
                <div className="stage-section">
                  <h2>
                    Gruppespill{' '}
                    {groupStagePoints > 0 && `(${groupStagePoints} poeng)`}
                  </h2>
                  <ul>
                    {sortedMatches.map((match) => {
                      const matchId = match.name;
                      const predictedResult = tip.groupStage[matchId];
                      const actualResult = getMatchResult(matchId);
                      const className = isCorrect(
                        predictedResult,
                        actualResult,
                      );

                      return predictedResult ? (
                        <li
                          key={matchId}
                          className={className}
                          style={{ fontSize: '0.8em' }}
                        >
                          {`${formatDate(match.date)} kl. ${match.time} - ${match.team1} - ${match.team2}:`}{' '}
                          <span style={{ textAlign: 'right' }}>
                            {predictedResult}
                          </span>
                        </li>
                      ) : null;
                    })}
                  </ul>
                </div>
                <div className="stage-section">
                  <h2>
                    Åttendelsfinale{' '}
                    {roundOf16Points > 0 && `(${roundOf16Points} poeng)`}
                  </h2>
                  <ul>
                    {tip.roundOf16 &&
                      Object.keys(tip.roundOf16)
                        .map((team) => {
                          const group = getGroupForTeam(team);
                          return { team, group };
                        })
                        .sort((a, b) => a.group.localeCompare(b.group))
                        .map(({ team, group }, teamIndex) => {
                          const className = getClassNameForTeam(
                            team,
                            correctRoundOf16Teams,
                            16,
                          );

                          return (
                            <li key={`r16-${teamIndex}`} className={className}>
                              <span className="team-name">{team}</span>
                              <span className="team-group">{group}</span>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="stage-section">
                  <h2>
                    Kvartfinale{' '}
                    {quarterFinalPoints > 0 && `(${quarterFinalPoints} poeng)`}
                  </h2>
                  <ul>
                    {tip.quarterFinals &&
                      Object.keys(tip.quarterFinals).map((team, teamIndex) => {
                        const className = getClassNameForTeam(
                          team,
                          correctQuarterFinalTeams,
                          8,
                        );

                        return (
                          <li key={`qf-${teamIndex}`} className={className}>
                            {team}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="stage-section">
                  <h2>
                    Semifinale{' '}
                    {semiFinalPoints > 0 && `(${semiFinalPoints} poeng)`}
                  </h2>
                  <ul>
                    {tip.semiFinals &&
                      Object.keys(tip.semiFinals).map((team, teamIndex) => {
                        const className = getClassNameForTeam(
                          team,
                          correctSemiFinalTeams,
                          4,
                        );

                        return (
                          <li key={`sf-${teamIndex}`} className={className}>
                            {team}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="stage-section">
                  <h2>Finale {finalPoints > 0 && `(${finalPoints} poeng)`}</h2>
                  <ul>
                    {tip.finals &&
                      Object.keys(tip.finals).map((team, teamIndex) => {
                        const className = getClassNameForTeam(
                          team,
                          correctFinalTeams,
                          2,
                        );

                        return (
                          <li key={`f-${teamIndex}`} className={className}>
                            {team}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="stage-section">
                  <h2>
                    Vinner {winnerPoints > 0 && `(${winnerPoints} poeng)`}
                  </h2>
                  <ul>
                    {tip.winner && (
                      <li
                        className={getClassNameForWinner(
                          tip.winner,
                          correctResults ? correctResults.correctWinner : '',
                        )}
                      >
                        {tip.winner}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {todaysMatch && (
        <div className="todays-matches">
          <div className="match-day">
            <h3>{`Dagens kamp: ${todaysMatch.team1} - ${todaysMatch.team2}`}</h3>
            <p>
              <strong>H:</strong>{' '}
              {getPredictionsForMatch(todaysMatch.name).H.join(', ') || 'Ingen'}
            </p>
            <p>
              <strong>U:</strong>{' '}
              {getPredictionsForMatch(todaysMatch.name).U.join(', ') || 'Ingen'}
            </p>
            <p>
              <strong>B:</strong>{' '}
              {getPredictionsForMatch(todaysMatch.name).B.join(', ') || 'Ingen'}
            </p>
          </div>
        </div>
      )}
      {!loading && (
        <div className="next-round-predictions">
          {renderWinnerTable(getWinnerPredictions(tips))}
        </div>
      )}
      <div className="rules-section">
        <h2>Regler ved poenglikhet</h2>
        <p>
          Ved poenglikhet vil antall poeng opparbeidet i følgende rekkefølge
          telle ekstra:
        </p>
        <ol>
          <li>Vinner av EM</li>
          <li>Lag i finale</li>
          <li>Lag i semifinale</li>
          <li>Lag i kvartfinale</li>
          <li>Lag i åttendelsfinale</li>
        </ol>
      </div>
    </div>
  );
};

export default Overview;
