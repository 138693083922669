export const allMatches = [
  {
    group: 'Gruppe A',
    date: '2024-06-14',
    time: '21:00',
    team1: 'Tyskland',
    team2: 'Skottland',
    name: 'groupA1',
    result: '-',
  },
  {
    group: 'Gruppe B',
    date: '2024-06-15',
    time: '18:00',
    team1: 'Spania',
    team2: 'Kroatia',
    name: 'groupB1',
    result: '-',
  },
  {
    group: 'Gruppe C',
    date: '2024-06-16',
    time: '21:00',
    team1: 'Serbia',
    team2: 'England',
    name: 'groupC1',
    result: '-',
  },
  {
    group: 'Gruppe D',
    date: '2024-06-17',
    time: '21:00',
    team1: 'Østerrike',
    team2: 'Frankrike',
    name: 'groupD1',
    result: '-',
  },
  {
    group: 'Gruppe F',
    date: '2024-06-18',
    time: '18:00',
    team1: 'Tyrkia',
    team2: 'Georgia',
    name: 'groupF1',
    result: '-',
  },
  {
    group: 'Gruppe A',
    date: '2024-06-19',
    time: '21:00',
    team1: 'Skottland',
    team2: 'Sveits',
    name: 'groupA2',
    result: '-',
  },
  {
    group: 'Gruppe B',
    date: '2024-06-20',
    time: '21:00',
    team1: 'Spania',
    team2: 'Italia',
    name: 'groupB2',
    result: '-',
  },
  {
    group: 'Gruppe D',
    date: '2024-06-21',
    time: '21:00',
    team1: 'Nederland',
    team2: 'Frankrike',
    name: 'groupD2',
    result: '-',
  },
  {
    group: 'Gruppe E',
    date: '2024-06-22',
    time: '21:00',
    team1: 'Belgia',
    team2: 'Romania',
    name: 'groupE1',
    result: '-',
  },
  {
    group: 'Gruppe A',
    date: '2024-06-23',
    time: '21:00',
    team1: 'Sveits',
    team2: 'Tyskland',
    name: 'groupA3',
    result: '-',
  },
  {
    group: 'Gruppe B',
    date: '2024-06-24',
    time: '21:00',
    team1: 'Kroatia',
    team2: 'Italia',
    name: 'groupB3',
    result: '-',
  },
  {
    group: 'Gruppe C',
    date: '2024-06-25',
    time: '21:00',
    team1: 'Danmark',
    team2: 'Serbia',
    name: 'groupC2',
    result: '-',
  },
  {
    group: 'Gruppe E',
    date: '2024-06-26',
    time: '18:00',
    team1: 'Ukraina',
    team2: 'Belgia',
    name: 'groupE2',
    result: '-',
  },
];

export const selectedMatches = [
  'groupA1',
  'groupB1',
  'groupC1',
  'groupD1',
  'groupF1',
  'groupA2',
  'groupB2',
  'groupD2',
  'groupE1',
  'groupA3',
  'groupB3',
  'groupC2',
  'groupE2',
];

export const groups = [
  { name: 'A', teams: ['Tyskland', 'Skottland', 'Ungarn', 'Sveits'] },
  { name: 'B', teams: ['Spania', 'Kroatia', 'Italia', 'Albania'] },
  { name: 'C', teams: ['Slovenia', 'Danmark', 'Serbia', 'England'] },
  { name: 'D', teams: ['Nederland', 'Østerrike', 'Frankrike', 'Polen'] },
  { name: 'E', teams: ['Belgia', 'Slovakia', 'Romania', 'Ukraina'] },
  { name: 'F', teams: ['Tyrkia', 'Georgia', 'Portugal', 'Tsjekkia'] },
];

export const allTeams = [
  'Tyskland',
  'Skottland',
  'Ungarn',
  'Sveits',
  'Spania',
  'Kroatia',
  'Italia',
  'Albania',
  'Slovenia',
  'Danmark',
  'Serbia',
  'England',
  'Nederland',
  'Østerrike',
  'Frankrike',
  'Polen',
  'Belgia',
  'Slovakia',
  'Romania',
  'Ukraina',
  'Tyrkia',
  'Georgia',
  'Portugal',
  'Tsjekkia',
];
