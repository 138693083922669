import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [selectedMatchResults, setSelectedMatchResults] = useState([]);
  const [selectedRoundOf16Teams, setSelectedRoundOf16Teams] = useState([]);
  const [selectedQuarterFinalTeams, setSelectedQuarterFinalTeams] = useState(
    [],
  );
  const [selectedSemiFinalTeams, setSelectedSemiFinalTeams] = useState([]);
  const [selectedFinalTeams, setSelectedFinalTeams] = useState([]);
  const [winner, setWinner] = useState('');
  const [tips, setTips] = useState([]);

  return (
    <AppContext.Provider
      value={{
        name,
        setName,
        selectedMatchResults,
        setSelectedMatchResults,
        selectedRoundOf16Teams,
        setSelectedRoundOf16Teams,
        selectedQuarterFinalTeams,
        setSelectedQuarterFinalTeams,
        selectedSemiFinalTeams,
        setSelectedSemiFinalTeams,
        selectedFinalTeams,
        setSelectedFinalTeams,
        winner,
        setWinner,
        tips,
        setTips,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
