import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDXDbtbbnJBbGcPaDfOmlyYp0GgnC4hZCQ',
  authDomain: 'em2024-98a1c.firebaseapp.com',
  projectId: 'em2024-98a1c',
  storageBucket: 'em2024-98a1c.appspot.com',
  messagingSenderId: '829454299178',
  appId: '1:829454299178:web:7a9f72da436e1dfb07d353',
  measurementId: 'G-QFXZ29TH2Y',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
getAnalytics(app);

export { db };
