import React from 'react';

import { Routes, Route } from 'react-router-dom';

import AdminPanel from './components/AdminPanel';
import Confirmation from './components/Confirmation';
import Overview from './components/Overview';
//import ProtectedRoute from './components/ProtectedRoute';
//import SubmitForm from './components/SubmitForm';
import { AppProvider } from './context/AppContext';
import './styles.css';

const App = () => {
  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/admin" element={<AdminPanel />} />
      </Routes>
    </AppProvider>
  );
};

export default App;
