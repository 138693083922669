import React, { useState, useEffect } from 'react';

import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import { db } from '../firebase';
import { allMatches, selectedMatches, groups, allTeams } from '../matches';

const AdminPanel = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [teamsOut, setTeamsOut] = useState([]);

  const [selectedMatchResults, setSelectedMatchResults] = useState(
    selectedMatches.map((matchName) => {
      const match = allMatches.find((m) => m.name === matchName);
      return { ...match, result: '-' };
    }),
  );
  const [correctRoundOf16Teams, setCorrectRoundOf16Teams] = useState([]);
  const [correctQuarterFinalTeams, setCorrectQuarterFinalTeams] = useState([]);
  const [correctSemiFinalTeams, setCorrectSemiFinalTeams] = useState([]);
  const [correctFinalTeams, setCorrectFinalTeams] = useState([]);
  const [correctWinner, setCorrectWinner] = useState('');

  const handleBack = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchPredictionsAndResults = async () => {
      const resultsDoc = await getDoc(doc(db, 'correctResults', 'results'));
      if (resultsDoc.exists()) {
        const resultsData = resultsDoc.data();
        setSelectedMatchResults(
          selectedMatches.map((matchName) => {
            const match = resultsData.matches.find((m) => m.name === matchName);
            return { ...match, result: match.result };
          }),
        );
        setCorrectRoundOf16Teams(resultsData.correctRoundOf16Teams || []);
        setCorrectQuarterFinalTeams(resultsData.correctQuarterFinalTeams || []);
        setCorrectSemiFinalTeams(resultsData.correctSemiFinalTeams || []);
        setCorrectFinalTeams(resultsData.correctFinalTeams || []);
        setCorrectWinner(resultsData.correctWinner || '');
        setTeamsOut(resultsData.teamsOut || []);
      }
      setLoading(false);
    };

    fetchPredictionsAndResults();
  }, []);

  const handleMatchChange = (index, value) => {
    const newMatches = [...selectedMatchResults];
    newMatches[index].result = value;
    setSelectedMatchResults(newMatches);
  };

  const handleTeamOutChange = (team) => {
    setTeamsOut((prevTeamsOut) =>
      prevTeamsOut.includes(team)
        ? prevTeamsOut.filter((t) => t !== team)
        : [...prevTeamsOut, team],
    );
  };

  const handleCheckboxChange = (
    team,
    setSelectedTeams,
    selectedTeams,
    maxSelection,
  ) => {
    if (selectedTeams.includes(team)) {
      setSelectedTeams(selectedTeams.filter((t) => t !== team));
    } else if (selectedTeams.length < maxSelection) {
      setSelectedTeams([...selectedTeams, team]);
    }
  };

  const handleWinnerChange = (team) => {
    if (correctWinner === team) {
      setCorrectWinner('');
    } else {
      setCorrectWinner(team);
    }
  };

  const updateResultsAndPoints = async () => {
    try {
      const correctResultsDoc = doc(db, 'correctResults', 'results');
      await setDoc(correctResultsDoc, {
        matches: selectedMatchResults,
        correctRoundOf16Teams,
        correctQuarterFinalTeams,
        correctSemiFinalTeams,
        correctFinalTeams,
        correctWinner,
        teamsOut,
      });
      alert('Riktige resultater og poeng oppdatert!');
    } catch (error) {
      console.error('Error updating results and points: ', error);
      alert('Feil ved oppdatering av resultater og poeng.');
    }
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long' };
    return new Date(dateString).toLocaleDateString('no-NO', options);
  };

  if (loading) {
    return <div>Laster...</div>;
  }

  return (
    <div className="form-container">
      <button onClick={handleBack} className="back-button">
        Tilbake til hovedsiden
      </button>
      <div className="correct-results-form">
        <h3>Gruppespill</h3>
        {selectedMatchResults.map((match, index) => (
          <div
            key={index}
            className="group-container"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="match-label">
              {match.team1} - {match.team2} ({formatDate(match.date)} kl.{' '}
              {match.time}):
            </div>
            <select
              value={match.result}
              onChange={(e) => handleMatchChange(index, e.target.value)}
              required
              className="select-field"
              style={{
                marginLeft: 'auto',
                width: '60px',
              }}
            >
              <option value="-">-</option>
              <option value="H">H</option>
              <option value="U">U</option>
              <option value="B">B</option>
            </select>
          </div>
        ))}
        <div className="group-container">
          <h3>Videre til åttendelsfinale</h3>
          {groups.map((group) =>
            group.teams.map((team) => (
              <label key={team} className="team-label">
                <input
                  type="checkbox"
                  value={team}
                  checked={correctRoundOf16Teams.includes(team)}
                  onChange={() =>
                    handleCheckboxChange(
                      team,
                      setCorrectRoundOf16Teams,
                      correctRoundOf16Teams,
                      16,
                    )
                  }
                  className="checkbox-field"
                />
                {team}
              </label>
            )),
          )}
        </div>
        <div className="group-container">
          <h3>Videre til kvartfinale</h3>
          {correctRoundOf16Teams.map((team) => (
            <label key={team} className="team-label">
              <input
                type="checkbox"
                value={team}
                checked={correctQuarterFinalTeams.includes(team)}
                onChange={() =>
                  handleCheckboxChange(
                    team,
                    setCorrectQuarterFinalTeams,
                    correctQuarterFinalTeams,
                    8,
                  )
                }
                className="checkbox-field"
              />
              {team}
            </label>
          ))}
        </div>
        <div className="group-container">
          <h3>Videre til semifinale</h3>
          {correctQuarterFinalTeams.map((team) => (
            <label key={team} className="team-label">
              <input
                type="checkbox"
                value={team}
                checked={correctSemiFinalTeams.includes(team)}
                onChange={() =>
                  handleCheckboxChange(
                    team,
                    setCorrectSemiFinalTeams,
                    correctSemiFinalTeams,
                    4,
                  )
                }
                className="checkbox-field"
              />
              {team}
            </label>
          ))}
        </div>
        <div className="group-container">
          <h3>Finalelag</h3>
          {correctSemiFinalTeams.map((team) => (
            <label key={team} className="team-label">
              <input
                type="checkbox"
                value={team}
                checked={correctFinalTeams.includes(team)}
                onChange={() =>
                  handleCheckboxChange(
                    team,
                    setCorrectFinalTeams,
                    correctFinalTeams,
                    2,
                  )
                }
                className="checkbox-field"
              />
              {team}
            </label>
          ))}
        </div>
        <div className="group-container">
          <h3>Vinner</h3>
          {correctFinalTeams.map((team) => (
            <label key={team} className="team-label">
              <input
                type="checkbox"
                value={team}
                checked={correctWinner === team}
                onChange={() => handleWinnerChange(team)}
                className="checkbox-field"
              />
              {team}
            </label>
          ))}
        </div>
        <div className="group-container">
          <h3>Lag ute av turneringen</h3>
          {allTeams.map((team) => (
            <label key={team} className="team-label">
              <input
                type="checkbox"
                value={team}
                checked={teamsOut.includes(team)}
                onChange={() => handleTeamOutChange(team)}
                className="checkbox-field"
              />
              {team}
            </label>
          ))}
        </div>
      </div>
      <button onClick={updateResultsAndPoints} className="update-button">
        Oppdater resultater
      </button>
    </div>
  );
};

export default AdminPanel;
